import React from 'react';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { logout } from '../../../features/auth/slices/userSlice';
import { Logout, Settings } from '@mui/icons-material';
import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useTheme } from '@mui/material/styles';

const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

export default function AccountMenu() {
  const userSlice = useAppSelector(state => state.user);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {

    // todo: move to services directory
    axios.defaults.withCredentials = true;

    axios.delete(`${process.env.REACT_APP_AUTH_URL}/users/sign_out`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': Cookies.get('csrf_token'),
      },
      withCredentials: true
    })
    .then(() => {
      dispatch(logout());
      window.location.href = `${process.env.REACT_APP_AUTH_URL}/users/sign_in`;
    })
    .catch((error) => {
      console.log(error);
    });

    // remove cookies
    // Cookies.remove('user_name', { path: '/', domain: COOKIE_DOMAIN });
    // Cookies.remove('user_avatar', { path: '/', domain: COOKIE_DOMAIN });
    // Cookies.remove('current_workspace', { path: '/', domain: COOKIE_DOMAIN });
    // Cookies.remove('membership_setup', { path: '/', domain: COOKIE_DOMAIN });
    // Cookies.remove('membership_role', { path: '/', domain: COOKIE_DOMAIN });
    Cookies.remove('csrf_token', { path: '/', domain: COOKIE_DOMAIN });
    Cookies.remove('referrer_oath', { path: '/', domain: COOKIE_DOMAIN });
    Cookies.remove('_prodopshq_be_session', { path: '/', domain: COOKIE_DOMAIN });
  };

  return (
    <div>

      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{
            border: '1px solid',
            ...(theme.palette.mode === 'light' && {
              borderColor: theme.palette.grey[100],
              '&:hover': {
                backgroundColor: theme.palette.grey[100],
              },
            }),
            ...(theme.palette.mode === 'dark' && {
              borderColor: theme.palette.grey[400],
              '&:hover': {
                backgroundColor: theme.palette.grey[400],
              },
            }),

            ml: 2
          }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar alt={userSlice.name} src={userSlice.avatar} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  )
}
