// src/features/apps/pages/CustomFieldMappingPage.tsx

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  CircularProgress,
  Alert,
  Box,
  Button,
  TextField,
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import {
  useGetCustomFieldsFromEHQuery,
  useGetCustomFieldMappingsQuery,
  useSetCustomFieldMappingsMutation
} from '../api/customFieldApi';
import { ICustomField } from '../../../models/customField.interface';

export default function CustomFieldMappingPage() {
  const { appId, orgId } = useParams<{ appId?: string; orgId?: string }>();
  const navigate = useNavigate();
  const [githubLoginField, setGithubLoginField] = useState<ICustomField | null>(null);
  const [teamField, setTeamField] = useState<ICustomField | null>(null);
  const [careerLevelField, setCareerLevelField] = useState<ICustomField | null>(null);
  const [specialisationField, setSpecialisationField] = useState<ICustomField | null>(null);

  // Fetch custom field mappings
  const {
    data: customFieldMappings,
    isLoading: isMappingsLoading,
    isError: isMappingsError,
    error: mappingsError,
  } = useGetCustomFieldMappingsQuery(
    { appId: appId ?? '', orgId: orgId ?? '' },
    { refetchOnMountOrArgChange: true }
  );

  // Fetch custom fields from EH
  const {
    data: customFieldsFromEH,
    isLoading: isFieldsLoading,
    isError: isFieldsError,
    error: fieldsError,
  } = useGetCustomFieldsFromEHQuery(
    { appId: appId ?? '', orgId: orgId ?? '' },
    { refetchOnMountOrArgChange: true }
  );

  const [setCustomFieldMappings, { isLoading: isSaving }] = useSetCustomFieldMappingsMutation();

  // Pre-populate fields when data is available
  useEffect(() => {
    if (customFieldsFromEH && customFieldMappings) {
      const findFieldByExternalId = (external_id: string): ICustomField | undefined =>
        customFieldsFromEH.find((field) => field.external_id === external_id);

      Object.entries(customFieldMappings).forEach(([mapped_field_type, mapping]) => {
        const field = findFieldByExternalId(mapping.external_id);
        if (!field) return;

        switch (mapped_field_type) {
          case 'github_login':
            setGithubLoginField(field);
            break;
          case 'team':
            setTeamField(field);
            break;
          case 'career_level':
            setCareerLevelField(field);
            break;
          case 'specialisation':
            setSpecialisationField(field);
            break;
          default:
            // Handle unknown field types if necessary
            break;
        }
      });
    }
  }, [customFieldsFromEH, customFieldMappings]);

  const handleSubmit = async () => {
    if (!githubLoginField) {
      alert('Please select the custom field for GitHub Login.');
      return;
    }

    try {
      await setCustomFieldMappings({
        appId: appId ?? '',
        orgId: orgId ?? '',
        mappings: {
          career_level: careerLevelField
            ? {
                custom_field_name: careerLevelField.name,
                custom_field_external_id: careerLevelField.external_id,
              }
            : null,
          specialisation: specialisationField
            ? {
                custom_field_name: specialisationField.name,
                custom_field_external_id: specialisationField.external_id,
              }
              : null,
          github_login: {
            custom_field_name: githubLoginField.name,
            custom_field_external_id: githubLoginField.external_id,
          },
          team: teamField
            ? {
                custom_field_name: teamField.name,
                custom_field_external_id: teamField.external_id,
              }
            : null,
        },
      }).unwrap();
      navigate(`/apps/${appId}/eh/organisations/${orgId}/import_review`);
    } catch (error) {
      console.error('Error saving custom field mappings:', error);
    }
  };

  if (isMappingsLoading || isFieldsLoading) {
    return <CircularProgress />;
  }

  if (isMappingsError) {
    return (
      <Alert severity="error">
        Failed to load custom field mappings: {mappingsError && 'status' in mappingsError ? mappingsError.status : ''}
      </Alert>
    );
  }

  if (isFieldsError) {
    return (
      <Alert severity="error">
        Failed to load custom fields: {fieldsError && 'status' in fieldsError ? fieldsError.status : ''}
      </Alert>
    );
  }

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Map Custom Fields
      </Typography>

      <Box sx={{ flexGrow: 1, maxWidth: 600, mx: 'auto' }}>
        <Typography variant="body1" gutterBottom>
          Please select the custom fields that correspond to the following:
        </Typography>

        {/* Career Levels Field (Optional) */}
        <Autocomplete
          options={customFieldsFromEH || []}
          getOptionLabel={(option) => option.name}
          value={careerLevelField}
          onChange={(event, newValue) => setCareerLevelField(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Career Levels (Optional)"
              variant="outlined"
              margin="normal"
            />
          )}
        />

        {/* Engineering Specialisation (Optional) */}
        <Autocomplete
          options={customFieldsFromEH || []}
          getOptionLabel={(option) => option.name}
          value={specialisationField}
          onChange={(event, newValue) => setSpecialisationField(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Engineering Specialisation (Optional)"
              variant="outlined"
              margin="normal"
            />
          )}
        />

        {/* GitHub Login Field (Required) */}
        <Autocomplete
          options={customFieldsFromEH || []}
          getOptionLabel={(option) => option.name}
          value={githubLoginField}
          onChange={(event, newValue) => setGithubLoginField(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="GitHub Login (Required)"
              required
              variant="outlined"
              margin="normal"
            />
          )}
        />

        {/* Teams Field (Optional) */}
        <Autocomplete
          options={customFieldsFromEH || []}
          getOptionLabel={(option) => option.name}
          value={teamField}
          onChange={(event, newValue) => setTeamField(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Teams (Optional)"
              variant="outlined"
              margin="normal"
            />
          )}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSaving}
          sx={{ mt: 2 }}
        >
          Save and Continue
        </Button>
      </Box>
    </>
  );
}
