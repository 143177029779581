import React, { useRef, useState } from 'react';
import { useGetTeamsQuery, useImportTeamsMutation, useUpdateTeamsAnalyseMutation } from '../api/teamApi';
import { useParams } from 'react-router-dom';
import {
  Typography,
  CircularProgress,
  Alert,
  useTheme,
  Box,
  Button,
} from '@mui/material';
import { AgGridReact } from '@ag-grid-community/react';
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef, ModuleRegistry, ICellRendererParams
 } from '@ag-grid-community/core';
import { ITeam } from '../../../models/team.interface';

ModuleRegistry.registerModules([ ClientSideRowModelModule ]);

export default function TabularTeamsPage() {
  const { appId } = useParams<{ appId: string }>();
  const { orgId } = useParams<{ orgId: string }>();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const gridClassName = isDarkMode ? "ag-theme-quartz-dark" : "ag-theme-quartz";

  const {
    data: teams,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetTeamsQuery({appId, orgId});

  const [importTeams, { isLoading: isImporting, isError: isImportError }] = useImportTeamsMutation();

  const [updateTeamsAnalyse, { isLoading: isUpdating }] = useUpdateTeamsAnalyseMutation();

  const [hasAttemptedImport, setHasAttemptedImport] = useState(false);

  const gridRef = useRef<AgGridReact<ITeam>>(null);

  const [selectedTeams, setSelectedTeams] = useState<ITeam[]>([]);

  React.useEffect(() => {
    if (!isLoading && !isError && (!teams || teams.length === 0) && !hasAttemptedImport) {
      setHasAttemptedImport(true);
      importTeams({ appId, orgId })
        .unwrap()
        .then(() => {
          // After importing, refetch the teams
          refetch();
        })
        .catch((err) => {
          console.error("Error importing teams:", err);
        });
    }
  }, [isLoading, isError, teams, hasAttemptedImport, appId, orgId, importTeams, refetch]);

  const onSelectionChanged = () => {
    const selectedNodes = gridRef.current?.api.getSelectedNodes();
    const selectedData = selectedNodes?.map(node => node.data) || [];
    setSelectedTeams(selectedData);
  };

  const handleUpdateAnalyse = async () => {
    if (selectedTeams.length === 0) return;


    const teamIds = selectedTeams.map(team => team.id)
      .filter((id): id is number => id !== null && id !== undefined);
    try {
      await updateTeamsAnalyse({ appId, orgId, teamIds, analyse: true }).unwrap();
      // Optionally, refetch teams or update state
      refetch();
      // Clear selection
      gridRef.current?.api.deselectAll();
      setSelectedTeams([]);
    } catch (err) {
      console.error("Error updating teams:", err);
    }
  };

  const columnDefs: ColDef<ITeam>[] = [
    {
      headerName: '',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressSizeToFit: true,
      filter: false,
      sortable: false,
      width: 50,       // Set a fixed width
      minWidth: 50,    // Ensure the width doesn't shrink
      maxWidth: 50,    // Ensure the width doesn't grow
    },
    { field: 'id', headerName: 'id', hide: true },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'analyse', headerName: 'Analyse', flex: 1, cellRenderer: (params: ICellRendererParams) => params.value ? 'Yes' : 'No' },
  ];

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return (
      <Alert severity="error">
        Failed to load teams: {error && 'status' in error ? error.status : ''}
      </Alert>
    );
  }

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Employment Hero Teams
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        {isLoading || isImporting ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
              <CircularProgress />
            </Box>
          ) : isError || isImportError ? (
            <Typography color="error">Error loading teams!</Typography>
          ) : (!teams || teams.length === 0) ? (
              <Box>
                <Typography>No Teams were found but we are attempting to fetch the latest Teams...</Typography>
                {isImporting && <CircularProgress />}
              </Box>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateAnalyse}
                disabled={selectedTeams.length === 0 || isUpdating}
                sx={{ mb: 2 }}
              >
                {isUpdating ? 'Updating...' : 'Analyse'}
              </Button>
              <div
                className={gridClassName}
                style={{ height: 500 }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={teams}
                  columnDefs={columnDefs}
                  rowSelection="multiple"
                  onSelectionChanged={onSelectionChanged}
                  suppressRowClickSelection={false}
                  defaultColDef={{
                    flex: 1,
                    minWidth: 100,
                    resizable: true,
                    sortable: true,
                    filter: true,
                  }}
                />
              </div>
            </>
          )}
      </Box>
    </>
  );
}
