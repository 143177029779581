import React, { useEffect, useState } from 'react'
import { Button, Card, CardContent, Chip, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Tab, Tabs, Typography } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import { useNavigate } from 'react-router-dom';
import GithubApp from '../components/GithubApp';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FormatListBulleted, DateRange, MoreHoriz } from '@mui/icons-material';
import { useGetAppsQuery, useGetAppStatusesQuery } from '../api/appApi';
import EmploymentHero from '../components/EmploymentHero';

interface MenuItemConfig {
  text: string;
  icon: React.ReactNode;
  onClick: (appId: number) => void;
}

export default function AppsPage() {
  const navigate = useNavigate();
  const [tab, setTab] = useState<number>(0);
  const { data: appsData } = useGetAppsQuery();
  const { data: appsStatusData } = useGetAppStatusesQuery();
  const [appStatusMap, setAppStatusMap] = useState<{ [key: number]: boolean }>({});

  const [anchorEl, setAnchorEl] = React.useState<{ [key: string]: null | HTMLElement }>({});
  // const open = Boolean(anchorEl);

  // When appsStatusData arrives, map it to the appStatusMap state
  useEffect(() => {
    if (appsStatusData) {
      const statusMap = appsStatusData.reduce((acc: { [key: number]: boolean }, status: { id: number, connected: boolean }) => {
        acc[status.id] = status.connected;
        return acc;
      }, {});
      setAppStatusMap(statusMap);
    }
  }, [appsStatusData]);

  const handleClick = (appId: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(prevState => ({ ...prevState, [appId]: event.currentTarget }));
  };

  const handleClose = (appId: number) => {
    setAnchorEl(prevState => ({ ...prevState, [appId]: null }));
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleReconnectClick = () => {
    window.location.href = `${process.env.REACT_APP_AUTH_URL}/apps/github`;
  };

  // Configuration object mapping app types to their menu items
  const menuItemsConfig: { [key: string]: MenuItemConfig[] } = {
    github: [
      {
        text: 'Manage Repositories',
        icon: <FormatListBulleted fontSize="small" />,
        onClick: (appId: number) => navigate(`/apps/${appId}/github/repositories`),
      },
      {
        text: 'Manage Stats Insights',
        icon: <DateRange fontSize="small" />,
        onClick: (appId: number) => navigate(`/apps/${appId}/github/stats`),
      },
    ],
    employment_hero: [
      {
        text: 'Manage Organisations',
        icon: <FormatListBulleted fontSize="small" />,
        onClick: (appId: number) => navigate(`/apps/${appId}/eh/organisations`),
      },
    ],
    // Add more app types and their menu items here as needed
  };

  return (
    <div>
      <Tabs value={tab} onChange={handleChange} style={{ marginBottom: 20 }} >
        <Tab label="Manage your Apps" />
        <Tab label="Connect new Apps" />
      </Tabs>
      <div
        role="tabpanel"
        hidden={tab !== 0}
        id="simple-tabpanel-0"
        aria-labelledby="simple-tab-0"
      >
        {appsData?.map((app) => {
          const menuItems = menuItemsConfig[app.appType] || [];
          return (
            <Card key={app.id} sx={{ minWidth: 275, marginBottom: 2 }}>
              <CardContent sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <GitHubIcon fontSize="large" sx={{ marginRight: 2 }} />
                  <div>
                    <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                      {app.organisation}
                      {app.appType === 'github' && appStatusMap[app.id] ? (
                        <Button
                          size="small"
                          color="secondary"
                          sx={{ marginLeft: 2 }}
                          onClick={() => window.open(`https://github.com/organizations/${app.organisation}/settings/installations/${app.installation_id}`, '_blank')}
                          endIcon={<OpenInNewIcon />}
                        >
                          github.com
                        </Button>
                      ) : null}
                    </Typography>
                    {app.appType === 'github' && (
                      <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                        Analysing {app.bucket_count} repositories
                      </Typography>
                    )}
                    {appStatusMap[app.id] !== undefined ? (
                      appStatusMap[app.id] ? (
                        <Chip label="Connected" color="success" variant="outlined" size="small" />
                      ) : (
                        <Chip label="Not connected" color="error" variant="outlined" size="small" />
                      )
                    ) : (
                      <Chip label="Loading..." color="warning" variant="outlined" size="small" />
                    )}
                  </div>
                </div>
                {appStatusMap[app.id] !== undefined && !appStatusMap[app.id] ? (
                  <Button variant="contained" onClick={handleReconnectClick} color="secondary">
                    Reconnect
                  </Button>
                ) : (
                  appStatusMap[app.id] !== undefined && (
                    <div>
                      <Button
                        aria-controls={anchorEl[app.id] ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEl[app.id] ? 'true' : undefined}
                        onClick={handleClick(app.id)}
                      >
                        <MoreHoriz />
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl[app.id]}
                        open={Boolean(anchorEl[app.id])}
                        onClose={() => handleClose(app.id)}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuList>
                          {menuItems.map((item, index) => (
                            <MenuItem key={index} onClick={() => item.onClick(app.id)}>
                              <ListItemIcon>{item.icon}</ListItemIcon>
                              <ListItemText>{item.text}</ListItemText>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Menu>
                    </div>
                  )
                )}
              </CardContent>
            </Card>
          );
        })}
      </div>
      <div
        role="tabpanel"
        hidden={tab !== 1}
        id="simple-tabpanel-1"
        aria-labelledby="simple-tab-1"
      >
        <GithubApp />
        <EmploymentHero />
      </div>
    </div>
  )
}
