import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  ModuleRegistry,
  GridOptions,
  FilterModel
} from "@ag-grid-community/core";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { IGithubStatRangeResponse } from '../../../../common/types/githubStatTypes';
import { useLazyGetByRangeGithubStatRangeQuery } from '../../api/githubStatRangeApi';
import { columnDefs } from './columnDefs';

ModuleRegistry.registerModules([ServerSideRowModelModule]);

interface GithubStatsGridProps {
  isDarkMode: boolean;
  analysisRange: string;
  onGridDataFetched?: (data: IGithubStatRangeResponse) => void;
}

const GithubStatsGrid = ({ isDarkMode, analysisRange, onGridDataFetched }: GithubStatsGridProps) => {
  const gridClassName = useMemo(() => {
    return isDarkMode ? "ag-theme-quartz-dark" : "ag-theme-quartz";
  }, [isDarkMode]);
  // TODO: change height based on screen size
  // big monitor: 900px
  // laptop: 600px
  // mobile: 400px
  const gridStyle = useMemo(() => ({ height: "900px", width: "100%" }), []);
  const [gridApi, setGridApi] = useState<GridReadyEvent['api'] | null>(null);

  const [fetchStatsRange] = useLazyGetByRangeGithubStatRangeQuery();

  useEffect(() => {
    if (gridApi) {
      const datasource = {
        getRows: (params: IServerSideGetRowsParams) => {
          const startRow = params.request.startRow ?? 0;
          const endRow = params.request.endRow ?? 50;
          const sortModel = params.request.sortModel;
          const filterModel = params.request.filterModel as FilterModel | undefined;

          fetchStatsRange({ analysisRange, startRow, endRow, sortModel, filterModel }).then((result) => {
            if (result.data && result.data.githubStats) {
              params.success({
                rowData: result.data.githubStats,
                rowCount: result.data.githubStatsTotalRowCount
              });
              // if (result.data.githubStats.length === 0) {
              //   params.api?.showNoRowsOverlay();
              // }

              onGridDataFetched?.(result.data);
            } else {
              params.fail();
            }
          }).catch(() => {
            params.fail();
          });
        }
      };

      // GG-EXAMPLE
      // - gpt generated code blocks
      // - copilot for quick fix
      // params.api!.setGridOption("serverSideDatasource", datasource);
      // params.api?.setGridOption("serverSideDatasource", datasource);
      gridApi.setGridOption("serverSideDatasource", datasource);
    }
  }, [gridApi, analysisRange, fetchStatsRange, onGridDataFetched]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api); // Save the gridApi for later use
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: true,
    };
  }, []);

  const gridOptions: GridOptions = useMemo(() => {
    return {
      rowModelType: "serverSide",
      cacheBlockSize: 50,
      maxBlocksInCache: 2,
      blockLoadDebounceMillis: 500,
      rowBuffer: 0,
      // debug: true,
    };
  }, []);

  return (
    <div
      className={gridClassName}
      style={gridStyle}
    >
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        gridOptions={gridOptions}
      />
    </div>
  );
};

export default GithubStatsGrid;
