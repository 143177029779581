import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../app/baseQuery';
import { IOrganisation } from '../../../models/organisation.interface';

export const organisationApi = createApi({
  reducerPath: 'organisationApi',
  baseQuery: baseQuery,
  tagTypes: ['Organisation'],
  endpoints: (builder) => ({
    getOrgs: builder.query<IOrganisation[], string | undefined>({
      query: (appId) => `/apps/${appId}/organisations`,
    }),
    updateAnalyse: builder.mutation<void, { appId: string; organisationId: number | null; analyse: boolean }>({
      query: ({ appId, organisationId, analyse }) => ({
        url: `/apps/${appId}/organisations/${organisationId}`,
        method: 'PUT',
        body: { analyse },
      }),
      // Optimistic update
      async onQueryStarted({ appId, organisationId, analyse }, { dispatch, queryFulfilled }) {
        // Optimistically update the cache value
        const patchResult = dispatch(
          organisationApi.util.updateQueryData('getOrgs', appId, (draft) => {
            const organisation = draft.find((org) => org.id === organisationId);
            if (organisation) {
              organisation.analyse = analyse;
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          // Revert the optimistic update if the mutation fails
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, { organisationId }) => [{ type: 'Organisation', id: organisationId ?? undefined }],
    }),
  }),
});

export const {
  useGetOrgsQuery,
  useUpdateAnalyseMutation,
} = organisationApi;
