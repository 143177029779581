import { fetchBaseQuery, FetchArgs, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

// Define the base query with default settings
export const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_URL}/api/v1`,
  prepareHeaders: (headers) => {
    const token = Cookies.get('csrf_token');
    if (token) {
      headers.set('X-CSRF-Token', token);
    }
    headers.set('Content-Type', 'application/json');
    return headers;
  },
  credentials: 'include',
});

// Enhanced baseQuery with redirect on auth errors
export const baseQueryWithRedirect: BaseQueryFn<string | FetchArgs, unknown, unknown> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    const status = (result.error as { status: number }).status;
    if (status === 401) {
      window.location.href = `${process.env.REACT_APP_AUTH_URL}/users/sign_in`;
      return result;
    }
  }

  return result;
};
