import React from 'react';
import { useGetOrgsQuery, useUpdateAnalyseMutation } from '../api/organisationApi';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Typography,
  CircularProgress,
  Alert,
  useTheme,
  Box,
  Stack,
  Paper,
  Chip,
} from '@mui/material';
import { IOrganisation } from '../../../models/organisation.interface';

export default function StackedOrganisationsPage() {
  const { appId } = useParams<{ appId: string }>();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const {
    data: organisations,
    isLoading,
    isError,
    error,
  } = useGetOrgsQuery(appId);

  const [updateAnalyse] = useUpdateAnalyseMutation();

  const handleAnalyseChange = async (organisation: IOrganisation) => {
    const newAnalyseValue = !organisation.analyse;

    try {
      // Run updateAnalyse only if newAnalyseValue is true
      if (newAnalyseValue) {
        await updateAnalyse({
          appId: appId ?? '',
          organisationId: organisation.id,
          analyse: newAnalyseValue,
        }).unwrap();
      }

      // Navigate to the organisation's teams page regardless of newAnalyseValue
      navigate(`/apps/${appId}/eh/organisations/${organisation.id}/departments`);
    } catch (err) {
      console.error("Error updating organisation:", err);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return (
      <Alert severity="error">
        Failed to load organisations: {error && 'status' in error ? error.status : ''}
      </Alert>
    );
  }

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Employment Hero Organisations
      </Typography>

      <Box sx={{ flexGrow: 1, maxWidth: 600, mx: 'auto' }}>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Please select the organisation you would like to import data from.
        </Typography>
        {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
              <CircularProgress />
            </Box>
          ) : isError ? (
            <Typography color="error">Error loading Organisations!</Typography>
          ) : (
            <Stack spacing={2}>
              {organisations?.map((organisation) => (
                <Paper
                  key={organisation.id}
                  elevation={isDarkMode ? 3 : 1}
                  onClick={() => handleAnalyseChange(organisation)}
                  sx={{
                    p: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    maxWidth: '100%',
                    '&:hover': {
                      backgroundColor: isDarkMode ? '#444' : '#f5f5f5',
                    },
                  }}
                >
                  <Box>
                    <Typography variant="h6">
                      <Box component="span" mr={1}>
                        {organisation.name}
                      </Box>
                      {organisation.analyse && (
                        <Chip label="Analysing" color="success" variant="outlined" size="small" />
                      )}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Location: {organisation.location}
                    </Typography>
                  </Box>
                </Paper>
              ))}
            </Stack>
          )}
      </Box>
    </>
  );
}
