// src/features/apps/pages/ImportReviewPage.tsx

import React, { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  Typography,
  CircularProgress,
  Alert,
  Box,
  Button,
  Switch,
  FormControlLabel,
  Paper,
  Divider,
  useTheme,
} from '@mui/material';
import { AgGridReact } from '@ag-grid-community/react';
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef, ModuleRegistry
 } from '@ag-grid-community/core';
import { useGetDepartmentsQuery } from '../api/departmentApi';
import { useGetCustomFieldMappingsQuery } from '../api/customFieldApi';
import { useImportMembershipsMutation } from '../../people/api/peopleApi';
import { IDepartment } from '../../../models/department.interface';

ModuleRegistry.registerModules([ ClientSideRowModelModule ]);

export default function ImportReviewPage() {
  const { appId, orgId } = useParams<{ appId: string; orgId: string }>();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const gridClassName = isDarkMode ? "ag-theme-quartz-dark" : "ag-theme-quartz";
  const [includeTerminated, setIncludeTerminated] = useState<boolean>(true);

  const {
    data: departments,
    isLoading: isLoadingDepartments,
    isError: isErrorDepartments,
  } = useGetDepartmentsQuery({ appId: appId ?? '', orgId: orgId ?? '' });

  const {
    data: customFieldMappings,
    isLoading: isLoadingMappings,
    isError: isErrorMappings,
  } = useGetCustomFieldMappingsQuery({ appId: appId ?? '', orgId: orgId ?? '' });

  const [importEmployees, { isLoading: isImporting }] = useImportMembershipsMutation();

  const handleImport = async () => {
    if (appId && orgId) {
      try {
        await importEmployees({
          appId: appId,
          orgId: orgId,
          includeTerminated,
        }).unwrap();
        navigate('/people');
      } catch (error) {
        console.error('Error importing employees:', error);
      }
    } else {
      console.error('appId or orgId is missing');
    }
  };

  if (isLoadingDepartments || isLoadingMappings) {
    return <CircularProgress />;
  }

  if (isErrorDepartments || isErrorMappings) {
    return (
      <Alert severity="error">
        Failed to load data.
      </Alert>
    );
  }

  const departmentsToImport = departments ? departments.filter(dept => dept.analyse) : [];

  const columnDepartmentDefs: ColDef<IDepartment>[] = [
    { headerName: 'EH Departments for Import', field: 'name', flex: 1 },
  ];

  // Transform customFieldMappings into an array
  const customFieldMappingsArray = customFieldMappings
    ? Object.entries(customFieldMappings).map(([mapped_field_type, mapping]) => ({
        mapped_field_type,
        name: mapping.name,
        external_id: mapping.external_id,
      }))
    : [];

  const columnCustomFieldDefs: ColDef[] = [
    {
      headerName: 'POHQ Field',
      field: 'mapped_field_type',
      flex: 1,
      valueFormatter: (params) => formatFieldType(params.value),
    },
    { headerName: 'EH Custom Field', field: 'name', flex: 1 },
  ];

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Review and Confirm Import
      </Typography>

      {/* Confirm Departments */}
      <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Departments
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Typography variant="body1" gutterBottom>
          EH Departments will indicate which Employees are imported into POHQ
        </Typography>
        {departmentsToImport && departmentsToImport.length > 0 ? (
          <div className={gridClassName} style={{ height: 500 }}>
            <AgGridReact
              rowData={departmentsToImport}
              columnDefs={columnDepartmentDefs}
            />
          </div>
        ) : (
          <Typography>No departments found.</Typography>
        )}
      </Paper>

      {/* Confirm Custom Field Mappings */}
      <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Custom Field Mappings
        </Typography>
        <Divider sx={{ mb: 2 }} />
        {customFieldMappingsArray && customFieldMappingsArray.length > 0 ? (
          <div className={gridClassName} style={{ height: 200 }}>
            <AgGridReact
              rowData={customFieldMappingsArray}
              columnDefs={columnCustomFieldDefs}
              domLayout="autoHeight"
              headerHeight={40}
              rowHeight={40}
            />
          </div>
        ) : (
          <Typography>No custom field mappings found.</Typography>
        )}
      </Paper>

      {/* Include Terminated Employees */}
      <FormControlLabel
        control={
          <Switch
            checked={includeTerminated}
            onChange={(e) => setIncludeTerminated(e.target.checked)}
            color="primary"
          />
        }
        label="Include Terminated Employees (Recommended)"
      />

      {/* Instructions */}
      <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
        By proceeding, employees will be imported. You can review them at the <Link to="/people"><strong>People page</strong></Link> and invite them to log in.
      </Typography>

      {/* Confirm Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleImport}
        disabled={isImporting}
        sx={{ mt: 2 }}
      >
        Confirm and Import Employees
      </Button>
    </Box>
  );
}

// Helper function to format field types
function formatFieldType(fieldType: string): string {
  return fieldType
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
