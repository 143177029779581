import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { authApi } from '../features/auth/api/authApi';
import { appApi } from '../features/apps/api/appApi';
import { departmentApi } from '../features/apps/api/departmentApi';
import { githubStatRangesApi } from '../features/githubStatsRange/api/githubStatRangeApi';
import { githubStatsApi } from '../features/githubStats/api/githubStatsApi';
import { invitationApi } from '../features/people/api/invitationApi';
import { organisationApi } from '../features/apps/api/organisationApi';
import { peopleApi } from '../features/people/api/peopleApi';
import { teamApi } from '../features/apps/api/teamApi';
import { workspaceApi } from '../features/setup/workspace/api/workspaceApi';
import { customFieldApi } from '../features/apps/api/customFieldApi';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      appApi.middleware,
      customFieldApi.middleware,
      departmentApi.middleware,
      githubStatRangesApi.middleware,
      githubStatsApi.middleware,
      invitationApi.middleware,
      organisationApi.middleware,
      peopleApi.middleware,
      teamApi.middleware,
      workspaceApi.middleware
    ]),
});

// Define and export RootState and AppDispatch types
type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export type { RootState, AppDispatch };
export default store;
