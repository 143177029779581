import { combineReducers } from '@reduxjs/toolkit';
import userReducer from '../features/auth/slices/userSlice';
import githubStatsReducer from '../features/workspace/apps/github/githubStatsSlice';
import gitWeightingsReducer from '../features/workspace/apps/github/gitWeightingsSlice';
import { authApi } from '../features/auth/api/authApi';
import { appApi } from '../features/apps/api/appApi';
import { githubStatsApi } from '../features/githubStats/api/githubStatsApi';
import { githubStatRangesApi } from '../features/githubStatsRange/api/githubStatRangeApi';
import { invitationApi } from '../features/people/api/invitationApi';
import { organisationApi } from '../features/apps/api/organisationApi';
import { peopleApi } from '../features/people/api/peopleApi';
import { teamApi } from '../features/apps/api/teamApi';
import { workspaceApi } from '../features/setup/workspace/api/workspaceApi';
import { departmentApi } from '../features/apps/api/departmentApi';
import { customFieldApi } from '../features/apps/api/customFieldApi';

const rootReducer = combineReducers({
  user: userReducer,
  githubStats: githubStatsReducer,
  gitWeightings: gitWeightingsReducer,
  [authApi.reducerPath]: authApi.reducer,
  [appApi.reducerPath]: appApi.reducer,
  [customFieldApi.reducerPath]: customFieldApi.reducer,
  [departmentApi.reducerPath]: departmentApi.reducer,
  [githubStatsApi.reducerPath]: githubStatsApi.reducer,
  [githubStatRangesApi.reducerPath]: githubStatRangesApi.reducer,
  [invitationApi.reducerPath]: invitationApi.reducer,
  [organisationApi.reducerPath]: organisationApi.reducer,
  [peopleApi.reducerPath]: peopleApi.reducer,
  [teamApi.reducerPath]: teamApi.reducer,
  [workspaceApi.reducerPath]: workspaceApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
