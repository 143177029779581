import React, { useEffect, useRef, useState } from 'react';
import { useGetDepartmentsQuery, useImportDepartmentsMutation, useUpdateDepartmentsAnalyseMutation } from '../api/departmentApi';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Typography,
  CircularProgress,
  Alert,
  useTheme,
  Box,
  Button,
} from '@mui/material';
import { AgGridReact } from '@ag-grid-community/react';
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef, ModuleRegistry, ICellRendererParams, GridApi, FirstDataRenderedEvent
 } from '@ag-grid-community/core';
import { IDepartment, IDepartmentUpdate } from '../../../models/department.interface';

ModuleRegistry.registerModules([ ClientSideRowModelModule ]);

export default function TabularDepartmentsPage() {
  const { appId } = useParams<{ appId: string }>();
  const { orgId } = useParams<{ orgId: string }>();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const gridClassName = isDarkMode ? "ag-theme-quartz-dark" : "ag-theme-quartz";

  const {
    data: departments,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetDepartmentsQuery({appId, orgId});

  const [importDepartments, { isLoading: isImporting, isError: isImportError }] = useImportDepartmentsMutation();

  const [updateDepartmentsAnalyse, { isLoading: isUpdating }] = useUpdateDepartmentsAnalyseMutation();

  const [hasAttemptedImport, setHasAttemptedImport] = useState(false);

  const gridRef = useRef<GridApi | null>(null);

  const [selectedDepartments, setSelectedDepartments] = useState<IDepartment[]>([]);

  // Memoize the list of department IDs where analyse is true
  const selectedDepartmentIds = React.useMemo(() => {
    return departments
      ? departments.filter(dept => dept.analyse && dept.id !== null).map(dept => dept.id?.toString())
      : [];
  }, [departments]);

  useEffect(() => {
    if (!isLoading && !isError && (!departments || departments.length === 0) && !hasAttemptedImport) {
      setHasAttemptedImport(true);
      importDepartments({ appId, orgId })
        .unwrap()
        .then(() => {
          // After importing, refetch the departments
          refetch();
        })
        .catch((err) => {
          console.error("Error importing departments:", err);
        });
    }
  }, [isLoading, isError, departments, hasAttemptedImport, appId, orgId, importDepartments, refetch]);

  const onSelectionChanged = () => {
    const selectedNodes = gridRef.current?.getSelectedNodes();
    const selectedData = selectedNodes?.map(node => node.data) || [];
    setSelectedDepartments(selectedData);
  };

  const handleUpdateAnalyse = async () => {
    if (isUpdating) return; // Prevent multiple clicks

    const selectedIds = selectedDepartments
      .map(dept => dept.id)
      .filter((id): id is number => id !== null && id !== undefined);

    const allIds = departments
      ? departments.map(dept => dept.id).filter((id): id is number => id !== null && id !== undefined)
      : [];

    const unselectedIds = allIds.filter(id => !selectedIds.includes(id));

    // Prepare the updates payload
    const updates: IDepartmentUpdate[] = [
      ...selectedIds.map(id => ({ id, analyse: true })),
      ...unselectedIds.map(id => ({ id, analyse: false })),
    ];

    try {
      await updateDepartmentsAnalyse({
        appId,
        orgId,
        updates,
      }).unwrap();

      // Optionally, refetch departments to ensure UI consistency
      await refetch();

      // Navigate to the next page
      navigate(`/apps/${appId}/eh/organisations/${orgId}/custom_field_mapping`);
    } catch (err) {
      console.error("Error updating departments:", err);
      // Optionally, display an error message to the user
    }
  };

  const columnDefs: ColDef<IDepartment>[] = [
    {
      headerName: '',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressSizeToFit: true,
      filter: false,
      sortable: false,
      width: 50,       // Set a fixed width
      minWidth: 50,    // Ensure the width doesn't shrink
      maxWidth: 50,    // Ensure the width doesn't grow
    },
    { field: 'id', headerName: 'id', hide: true },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'analyse', headerName: 'Analyse', flex: 1, cellRenderer: (params: ICellRendererParams) => params.value ? 'Yes' : 'No' },
  ];

  // Use onFirstDataRendered to select rows where department.analyse is true
  const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
    gridRef.current = params.api;

    if (selectedDepartmentIds.length > 0) {
      selectedDepartmentIds.forEach(id => {
        if (id) {
          const node = params.api.getRowNode(id);
          if (node) {
            node.setSelected(true);
          }
        }
      });
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return (
      <Alert severity="error">
        Failed to load departments: {error && 'status' in error ? error.status : ''}
      </Alert>
    );
  }

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Employment Hero Departments
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6" color="textSecondary" gutterBottom>
        Choose the departments from which you want to import employees. You can select multiple departments.
        </Typography>
        {isLoading || isImporting ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
              <CircularProgress />
            </Box>
          ) : isError || isImportError ? (
            <Typography color="error">Error loading departments!</Typography>
          ) : (!departments || departments.length === 0) ? (
              <Box>
                <Typography>No Departments were found but we are attempting to fetch the latest Departments...</Typography>
                {isImporting && <CircularProgress />}
              </Box>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateAnalyse}
                disabled={isUpdating}
                sx={{ mb: 2 }}
              >
                {isUpdating ? 'Updating...' : 'Next'}
              </Button>
              <div
                className={gridClassName}
                style={{ height: 500 }}
              >
                <AgGridReact
                  rowData={departments}
                  columnDefs={columnDefs}
                  rowSelection="multiple"
                  onSelectionChanged={onSelectionChanged}
                  suppressRowClickSelection={false}
                  defaultColDef={{
                    flex: 1,
                    minWidth: 100,
                    resizable: true,
                    sortable: true,
                    filter: true,
                  }}
                  getRowId={(params) => params.data.id.toString()}
                  onFirstDataRendered={onFirstDataRendered}
                />
              </div>
            </>
          )}
      </Box>
    </>
  );
}
