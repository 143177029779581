import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../app/baseQuery';
import { ITeam } from '../../../models/team.interface';

export const teamApi = createApi({
  reducerPath: 'teamApi',
  baseQuery: baseQuery,
  tagTypes: ['Team', 'CustomField'],
  endpoints: (builder) => ({
    getTeams: builder.query<ITeam[], { appId: string | undefined; orgId: string | undefined }>({
      query: ({ appId, orgId }: { appId: string; orgId: string }) => `/apps/${appId}/organisations/${orgId}/teams`,
    }),
    importTeams: builder.mutation<ITeam[], { appId: string | undefined; orgId: string | undefined }>({
      query: ({ appId, orgId }) => ({
        url: `/apps/${appId}/organisations/${orgId}/teams/import`,
        method: 'POST',
      }),
      invalidatesTags: ['Team'],
    }),
    updateTeamsAnalyse: builder.mutation<void, { appId: string | undefined; orgId: string | undefined; teamIds: number[]; analyse: boolean }>({
      query: ({ appId, orgId, teamIds, analyse }) => ({
        url: `/apps/${appId}/organisations/${orgId}/teams/analyse`,
        method: 'PUT',
        body: { teamIds, analyse },
      }),
      invalidatesTags: ['Team'],
    }),
  }),
});

export const {
  useGetTeamsQuery,
  useImportTeamsMutation,
  useUpdateTeamsAnalyseMutation,
} = teamApi;
