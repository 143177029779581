import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../app/baseQuery';
import { IDepartment, IDepartmentUpdate } from '../../../models/department.interface';

export const departmentApi = createApi({
  reducerPath: 'departmentApi',
  baseQuery: baseQuery,
  tagTypes: ['Department'],
  endpoints: (builder) => ({
    getDepartments: builder.query<IDepartment[], { appId: string | undefined; orgId: string | undefined }>({
      query: ({ appId, orgId }: { appId: string; orgId: string }) => `/apps/${appId}/organisations/${orgId}/departments`,
      // providesTags: (result, error, { appId, orgId }) =>
      //   result
      //     ? [
      //         // Provide a tag for each department
      //         ...result.map(({ id }) => ({ type: 'Department' as const, id: id ?? undefined })),
      //         { type: 'Department', id: 'LIST' },
      //       ]
      //     : [{ type: 'Department', id: 'LIST' }],
    }),
    importDepartments: builder.mutation<IDepartment[], { appId: string | undefined; orgId: string | undefined }>({
      query: ({ appId, orgId }) => ({
        url: `/apps/${appId}/organisations/${orgId}/departments/import`,
        method: 'POST',
      }),
      invalidatesTags: ['Department'],
    }),
    updateDepartmentsAnalyse: builder.mutation<void, { appId: string | undefined; orgId: string | undefined; updates: IDepartmentUpdate[] }>({
      query: ({ appId, orgId, updates }) => ({
        url: `/apps/${appId}/organisations/${orgId}/departments/analyse`,
        method: 'PUT',
        body: { updates },
      }),
      invalidatesTags: ['Department'],
    }),
  }),
});

export const {
  useGetDepartmentsQuery,
  useImportDepartmentsMutation,
  useUpdateDepartmentsAnalyseMutation,
} = departmentApi;
