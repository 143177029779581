import React from 'react';
import Button from '@mui/material/Button';
import {
  AccordionDetails,
  Box,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import GitHubIcon from '@mui/icons-material/GitHub';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChecklistIcon from '@mui/icons-material/Checklist';
import Cookies from 'js-cookie';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const EmploymentHero = () => {
  const [clientId, setClientId] = React.useState('');
  const [clientSecret, setClientSecret] = React.useState('');

  // const handleClick = () => {
  //     window.location.href = `${process.env.REACT_APP_AUTH_URL}/apps/github`;
  // };

  // const [connectEmploymentHero, { isLoading, isError, error }] = useConnectEmploymentHeroMutation();

  // const handleConnect = async (e: React.FormEvent) => {
  //   e.preventDefault();

  //   try {
  //     const result = await connectEmploymentHero({
  //       client_id: clientId,
  //       client_secret: clientSecret,
  //     }).unwrap();

  //     window.location.href = result.redirect_url;
  //   } catch (err) {
  //     // Handle errors
  //     console.error('Connection failed:', err);
  //   }
  // };

  return (
    <Card sx={{ minWidth: 275, marginBottom: 2 }}>
      <Accordion expanded={true}>
        <AccordionSummary>
          <Box display="flex" alignItems="center" flexGrow={1}>
            <GitHubIcon />
            <Typography variant="h6" sx={{ fontWeight: 'bold', pl: 1, pr: 1 }} >
              Connect to your Employment Hero organisation
            </Typography>
            <Typography variant="h6">

            </Typography>
          </Box>
          {/* <Button variant="contained" color="primary" onClick={handleClick}>
            Connect
          </Button> */}
        </AccordionSummary>
        <AccordionDetails>
          <form
            action={`${process.env.REACT_APP_AUTH_URL}/apps/employment_hero`}
            method="post"
          >
            <input
              type="hidden"
              name="authenticity_token"
              value={
                Cookies.get('csrf_token') || ''
              }
            />
            <TextField
              label="Client ID"
              name="employment_hero[client_id]"
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Client Secret"
              name="employment_hero[client_secret]"
              value={clientSecret}
              onChange={(e) => setClientSecret(e.target.value)}
              fullWidth
              type="password"
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mt: 2 }}
            >
              Connect
            </Button>
          </form>
          <List>
            <ListItem>
              <ListItemIcon sx={{ color: 'inherit' }}>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Read-only access to your selected Organisations"}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ color: 'inherit' }}>
                <NewspaperIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Repository activity relates to commits, pull requests, issues and comments"}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ color: 'inherit' }}>
                <ChecklistIcon />
              </ListItemIcon>
              <ListItemText
                primary={"You select the Organisations you want to connect"}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default EmploymentHero
