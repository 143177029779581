import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../app/baseQuery';
import { ICustomField } from '../../../models/customField.interface';

type SetCustomFieldMappingsRequest = {
  appId: string;
  orgId: string;
  mappings: {
    career_level?: CustomFieldMappingInput | null;
    specialisation?: CustomFieldMappingInput | null;
    github_login: CustomFieldMappingInput;
    team?: CustomFieldMappingInput | null;
  };
};

type CustomFieldMappingInput = {
  custom_field_name: string;
  custom_field_external_id: string;
};

export type CustomFieldMappingsResponse = {
  [mapped_field_type: string]: {
    name: string;
    external_id: string;
  };
};

export const customFieldApi = createApi({
  reducerPath: 'customFieldApi',
  baseQuery: baseQuery,
  tagTypes: ['CustomField', 'CustomFieldMappings', 'CustomFields'],
  endpoints: (builder) => ({
    getCustomFieldsFromEH: builder.query<ICustomField[], { appId: string | undefined; orgId: string | undefined }>({
      query: ({ appId, orgId }: { appId: string; orgId: string }) => `/apps/${appId}/organisations/${orgId}/custom_field_mappings/import`,
      providesTags: (result, error, args) => [
        { type: 'CustomFields', id: `${args.appId}-${args.orgId}` },
      ],
    }),
    getCustomFieldMappings: builder.query<CustomFieldMappingsResponse, { appId: string; orgId: string }>({
      query: ({ appId, orgId }) => `/apps/${appId}/organisations/${orgId}/custom_field_mappings`,
      providesTags: (result, error, args) => [
        { type: 'CustomFieldMappings', id: `${args.appId}-${args.orgId}` },
      ],
    }),
    setCustomFieldMappings: builder.mutation<void, SetCustomFieldMappingsRequest>({
      query: ({ appId, orgId, mappings }) => ({
        url: `/apps/${appId}/organisations/${orgId}/custom_field_mappings`,
        method: 'POST',
        body: { mappings },
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'CustomFieldMappings', id: `${args.appId}-${args.orgId}` },
      ],
    }),
  }),
});

export const {
  useGetCustomFieldsFromEHQuery,
  useGetCustomFieldMappingsQuery,
  useSetCustomFieldMappingsMutation,
} = customFieldApi;
