import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../app/baseQuery';
import { IApp } from '../../../models/Workspace/app.interface';

export const appApi = createApi({
  reducerPath: 'appApi',
  baseQuery: baseQuery,
  tagTypes: ['App'],
  endpoints: (builder) => ({
    getApps: builder.query<IApp[], void>({
      query: () => `/apps`,
    }),
    getAppStatuses: builder.query<IApp[], void>({
      query: () => `/apps/connected`,
    }),
  }),
});

export const {
  useGetAppsQuery,
  useGetAppStatusesQuery
} = appApi;
